.logo{
    padding: 20px !important;
}

.pointer{
    cursor: pointer;
}

.lightRed {
    background-color: #FF7074;
    padding: 10px;
    float: right;
}

.lightGreen {
    background-color: #53D28C;
    padding: 10px;
}

.centerBorder{
    border-right: #e1def51f 1px solid;
    border-style: dashed;
    padding-right: 3px;    
}

.newsHeight{
    height: 87vh;
}

.fii thead th{
    block-size: 35px !important;
    padding: 0 !important;
    text-align: right !important;
}

.fii tbody td{
    block-size: 35px !important;
    padding: 0 !important;
}


.ipo thead th{
    block-size: 35px !important;
    padding: 0 !important;
}

.ipo tbody td{
    block-size: 35px !important;
    padding: 0 !important;
}
